"use strict";

// Declare app level module which depends on views, and components
var mainApp = angular.module("myApp", [
  "ngRoute",
  "ngDragDrop",
  "ui.tinymce",
  "angular-growl",
  "ui.bootstrap",
  "ui.grid",
  "ui.grid.edit",
  "ui.grid.rowEdit",
  "ui.grid.saveState",
  "ui.grid.pagination",
  "ui.grid.moveColumns",
  "ui.grid.resizeColumns",
  "ui.grid.selection",
  "infinite-scroll",
  "toggle-switch",
  "angular-input-stars",
  "angularUtils.directives.dirPagination",
  "MassAutoComplete",
  "angular.filter",
  "myApp.howToVideo",
  "ngIdle",
  "chart.js",
  "myApp.welcomeView",
  "myApp.voterSignup",
  "myApp.politicianSignup",
  "myApp.advocateSignup",
  "myApp.questionHome",
  "myApp.questionSubcategories",
  "myApp.question",
  "myApp.debateHome",
  "myApp.essay",
  "myApp.essayStaff",
  "myApp.essayDetails",
  "myApp.essayList",
  "myApp.newEssay",
  "myApp.essayUpdate",
  "myApp.essaySearch",
  "myApp.forum",
  "myApp.updateTopic",
  "myApp.forumthread",
  "myApp.forumSearch",
  "myApp.forumReply",
  "myApp.forumList",
  "myApp.publicProfile",
  "myApp.votingRecords",
  "myApp.sponsored",
  "myApp.topic",
  "myApp.localDebate",
  "myApp.learnHome",
  "myApp.pressHome",
  "myApp.liveFeed",
  "myApp.pressProfile",
  "myApp.pressComment",
  "myApp.signin",
  "myApp.advocateHome",
  "myApp.signup",
  "myApp.localPress",
  "myApp.advocateHome",
  "myApp.compareSelect",
  "myApp.compareSubjectWise",
  "myApp.compareSubcategories",
  "myApp.dashboard",
  "myApp.snapshot",
  "myApp.reports",
  "myApp.match",
  "myApp.emailunsubscribe",
  "myApp.updateUserAddress",
  "myApp.forgotpassword",
  "myApp.donate",
  "myApp.contactUs",
  "myApp.faq",
  "myApp.printUsers",
  "myApp.boardsofelections",
  "myApp.politicianThread",
  "myApp.essayPress",
  "myApp.newPressEssay",
  "myApp.pressEssayUpdate",
  "myApp.pressEssayStaff",
  "myApp.pressEssayDetails",
  "myApp.commonPhrases",
  "myApp.electionProblems",
  "myApp.aboutUs",
  "myApp.incumbentPolitician",
  "myApp.calendar",
  "myApp.digDeeperView",
  "myApp.fiveMatchView",
  "myApp.fiveMatchCompare",
  "myApp.fiveMinSaveUser",
  "myApp.fiveminsignin",
  "myApp.politician_compare",
  "myApp.compare_politician_questions",
  "myApp.gettingStarted",
  "myApp.gettingStartedOrg",
  "myApp.gettingStartedPol",
  "myApp.speakOut",
  "myApp.legislation",
  "myApp.geoCode",
  "myApp.polProfile",
  "myApp.polProfileSetUp",
  "myApp.winVoters",
  "myApp.allRatings",
  "myApp.compareRatings",
  "myApp.ratingsDetails",
  "myApp.polFinance",
  "myApp.research",
  "myApp.findMatch",
  "myApp.addRating",
  "myApp.userProfile",
  "myApp.checkRegistration",
  "myApp.registerToVote",
  "myApp.getAReminder",
  "myApp.absenteeBallots",
  "angular-js-xlsx",
  "ngJsonExportExcel",
  "youtube-embed",
  "myApp.ratingReviews",
  "myApp.votesmart",
  "myApp.allies",
  "updateMeta",
  "myApp.ballotMatch",
  "myApp.answeredQuestions",
  "myApp.followingUsers",
  "myApp.addCategory",
  "myApp.manageQue",
  "myApp.fiveMatchQuestions",
  "myApp.verifyUsers",
  "myApp.uploadPolitician",
  "myApp.restorePolitician",
  "myApp.uploadAdvocate",
  "myApp.uploadPress",
  "myApp.topicSpams",
  "myApp.allUsers",
  "myApp.allTopics",
  "myApp.allEssay",
  "myApp.allCongressman",
  "myApp.sendEmail",
  "myApp.organizedTable",
  "myApp.backup",
  "myApp.openFecCandidates",
  "myApp.openFecCommittee",
  "myApp.placesShape",
  "myApp.gridState",
  "myApp.uploadNewPolitician",
  "myApp.questionBackGround",
  "myApp.questionAnsweredBy",
  "myApp.questionSearch",
  "myApp.questionComparePolitician",
  "myApp.questionCompare",
  "myApp.totalUsers",
  "myApp.openState",
  "myApp.openStateBills",
  "myApp.viewBills",
  "myApp.viewBillDetails",
  "myApp.theConstitution",
  "myApp.getBillVoteRecords",
  "myApp.federalLegislation",
  "myApp.federalDetail",
  "myApp.federalBills",
  "myApp.getRollVotes",
  "myApp.getVotesDetails",
  "myApp.federalTextBills",
  "myApp.upcomingElection",
  "myApp.takeAction",
  "myApp.addPolitician",
  "ngCookies",
  "myApp.whatAmILookingAt1",
  "myApp.whatAmILookingAt2",
  "myApp.whatAmILookingAt3",
  "myApp.whatAmILookingAt4",
  "myApp.compareGuest",
  "myApp.compareGuestWith",
  "myApp.displayGuestForPolitician",
  "myApp.compareWithGroup",
  "myApp.showComments",
  "myApp.showAnswers",
  "myApp.showGroupAnswer",
  "myApp.trackUserActivities",
  "myApp.uploadElectionDate",
  "myApp.ratePolitician",
  "myApp.highlightedPost",
  "myApp.manageOrganizationQuestions",
  "myApp.allEndorsements",
  "myApp.addEndorsement",
  "myApp.endorsePolitician",
  "myApp.orgQuestions",
  "myApp.ballotMeasures",
  "myApp.orgRating",
  "myApp.allQuizzes",
  "myApp.invitePoliticians",
  "myApp.organizationQuiz",
  "myApp.orgActivityDetails",
  "myApp.alliesP",
  "myApp.compositeQuiz",
  "myApp.getPopularQues",
  "myApp.uploadFollowers",
  "myApp.followersQuiz",
  "myApp.quizzes",
  "myApp.quizzesOrg",
  "myApp.showPoliAns",
  "myApp.orgQuizComparison",
  "myApp.orgMatch",
  "myApp.quizResult",
  "myApp.showPoliQuiz",
  "myApp.showFollowersQuiz",
  "myApp.showPopUp",
  "myApp.poliAnswers",
  "myApp.politicianMatch",
  "myApp.listOfFollowers",
  "myApp.poliCompare",
  "myApp.matchOfFollowers",
  "myApp.popularQuizMatch",
  "myApp.getPopularComparison",
  "myApp.showFbMessage",
  "myApp.postOnFaceBook",
  "myApp.voterProfile",
  "myApp.showHideAns",
  "myApp.resetPassword",
  "myApp.stripe",
  "myApp.showMelisaData",
  "myApp.privacyPolicy",
  "myApp.discussWithOthers",
  "myApp.showMessage",
  "myApp.howABillBecomesALaw",
  "myApp.uploadBallotMeasures",
  "myApp.editBallotMeasures",
  "myApp.rateBallotMeasures",
  "myApp.ballotRating",
  "myApp.ballotReviewDetails",
  "myApp.endorseBallot",
  "myApp.showBallotEndorsement",
  "myApp.ballotThread",
  "myApp.createBallotThread",
  "myApp.showVotesmartAnsQues",
  "myApp.otherPoliticians",
  "myApp.showListOfMatch",
  "myApp.501c4Video",
  "myApp.exportOrgRatings",
  "myApp.exploreQuiz",
  "myApp.followersGroupAnswer",
  "myApp.findFollowerMatch",
  "myApp.showAllUsersMatch",
  "myApp.orgVideo",
  "myApp.politicianVideo",
  "myApp.postingHints",
  "myApp.representativeVideo",
  "myApp.fiveMinLanding",
  "myApp.trackFiveMinQuizAns",
  "myApp.uploadPopulationData",
  "myApp.trackPoliticians",
  "myApp.showCompetitors",
  "myApp.addFiveMinEssayQues",
  "myApp.fiveMinEssayAnswers",
  "myApp.addEssayAnswers",
  "myApp.compareEssayAns",
  "myApp.uploadBarAssociations",
  "myApp.createBanner",
  "myApp.generatePostCards",
  "myApp.addDebate",
  "myApp.sendInvitations",
//	"myApp.sampleElection",
]);

// mainApp.value("baseApiUrl", "http://localhost:8080");
mainApp.value("baseApiUrl", "https://test.votewise.net");
 //mainApp.value("baseApiUrl", "https://api.votewise.net");
//mainApp.value('baseApiUrl', 'http://162.243.162.231:8080');
mainApp.value("showConsole", false);

var tokenName = "VoteWise:JWT";
var tokenUserName = "VoteWise:User";
var tokenUserId = "VoteWise:UserId";
var tokenUserRole = "VoteWise:UserRole";
var tokenUserData = "VoteWise:UserData";

mainApp.service("tokenService", [
  "$http",
  "$rootScope",
  function ($http, $rootScope) {
    var tokenService = {
      saveToken: function (token) {
        window.localStorage[tokenName] = token;
      },
      getToken: function () {
        return window.localStorage[tokenName];
      },
      destroyToken: function () {
        window.localStorage.removeItem(tokenName);
        window.localStorage.removeItem(tokenUserName);
        window.localStorage.removeItem(tokenUserId);
        window.localStorage.removeItem(tokenUserRole);
        window.localStorage.removeItem(tokenUserData);

        $rootScope.userRole = null;
        $rootScope.User = null;
        $rootScope.UserId = null;
      },
      saveUserName: function (name) {
        window.localStorage[tokenUserName] = name;
      },
      saveUserRole: function (userobj) {
        $rootScope.userRole = userobj.userType;
        window.localStorage[tokenUserRole] = $rootScope.userRole;
      },
      getUserRole: function () {
        return window.localStorage[tokenUserRole];
      },
      getUserName: function () {
        return window.localStorage[tokenUserName];
      },
      saveUserId: function (id) {
        window.localStorage[tokenUserId] = id;
      },
      getUserId: function () {
        return window.localStorage[tokenUserId];
      },
      saveUserData: function (data) {
        window.localStorage[tokenUserData] = data;
      },
      getUserData: function () {
        return window.localStorage[tokenUserData];
      },
    };
    return tokenService;
  },
]);

mainApp.config([
  "$locationProvider",
  "$routeProvider",
  "growlProvider",
  "$httpProvider",
  "IdleProvider",
  "KeepaliveProvider",
  "$rootScopeProvider",
  "$compileProvider",
  function (
    $locationProvider,
    $routeProvider,
    growlProvider,
    $httpProvider,
    IdleProvider,
    KeepaliveProvider,
    $rootScopeProvider,
    $compileProvider
  ) {
    // $locationProvider.hashPrefix('!');
    // console.log('load');
    $compileProvider.aHrefSanitizationWhitelist(
      /^\s*(https?|file|tel|javascript):/
    );
    var url =
      "https://api.votewise.net/resources/stop-and-frisk-2-(1-of-1)-1488802259580.jpg";
    // var url = "stop-and-frisk-2-(1-of-1)-1488802259580.jpg";

    $("head > meta[property='og:image']").attr("content", url);
    IdleProvider.idle(2 * 60 * 60); // in seconds
    IdleProvider.timeout(30 * 60); // in seconds
    KeepaliveProvider.interval(40 * 60); // in seconds
    $rootScopeProvider.welcome = false;

    function checkUser() {
      var token = window.localStorage["VoteWise:JWT"];
      if (token) {
        // return $routeProvider.otherwise({ redirectTo: '/debateHome' });
        return $routeProvider.otherwise({
          redirectTo: "/questionHome",
        });
      }
      return $routeProvider.otherwise({
        redirectTo: "/welcome",
      });
    }
    checkUser();
    growlProvider.globalTimeToLive(60000);
    growlProvider.globalDisableCountDown(true);
    growlProvider.globalPosition("top-center");
    $httpProvider.interceptors.push("APIInterceptor");
  },
]);

mainApp.run([
  "tokenService",
  "MyService",
  "$location",
  "$rootScope",
  "$http",
  "$window",
  "growl",
  "$uibModal",
  "baseApiUrl",
  "apiCall",
  "$timeout",
  "$q",
  "$filter",
  "ngDialog",
  "$anchorScroll",
  "Idle",
  "$cookies",
  function (
    tokenService,
    MyService,
    $location,
    $rootScope,
    $http,
    $window,
    growl,
    $uibModal,
    baseApiUrl,
    apiCall,
    $timeout,
    $q,
    $filter,
    ngDialog,
    $anchorScroll,
    Idle,
    $cookies
  ) {
    $(window).load(function () {
      setTimeout;
      // Animate loader off screen
      $(".se-pre-con").fadeOut("slow");
    });
    $(window).load(function () {
      // Animate loader off screen
      $(".backLogo").fadeOut("slow");
    });
    $cookies.remove("showLongPopUp");
    $rootScope.welcome = false;
    Idle.watch();
    $rootScope.imgUrl = baseApiUrl + "/resources";
    $rootScope.redirectExceptions = ["forgot-password", "signin", "signup"];
    var path = $location.path();
    $window.ga("create", "UA-84996430-1", "auto");

    $rootScope.dynamicTitle =
      "VoteWise.net - In life, you can vote with wallet, with your voice, with your actions and at the ballot box. But it's important to always vote wisely.";
    $rootScope.dynamicDescription =
      "VoteWise.net is a non-partisan non-profit tool aimed at repairing democracy by connecting voters, politicians and organizations without advertising dollars getting in the way. There are different tools available to different types of users, so click on a user type below, watch the 2 minute video and start saving the world.";
    $rootScope.dynamicImage =
      "https://votewise.net/assets/images/VoteWiseMeta.jpg";
    MyService.ConsoleLog("Here in run");
    // track pageview on state change
    window.localStorage.removeItem("HistoryPage");
    $rootScope.$on("$routeChangeSuccess", function (event) {
      //console.log("path<><><><",$location.path());
      // //  MyService.ConsoleLog("path1<><><><",path);
      // $rootScope.title = window.localStorage.getItem('fbName');
      // $rootScope.ogImgUrl =$rootScope.imgUrl+'/'+ window.localStorage.getItem('fbImg');
      $window.ga("send", "pageview", $location.path());
    });

    // $rootScope.getElectionsDate = function () {
    //   MyService.ConsoleLog("In get elections date");
    //   MyService.getElectionsDate({}).then(function (success) {
    //     MyService.ConsoleLog("Elections date : ", success);
    //   }, function (err) {
    //     MyService.ConsoleLog("error : ", err);
    //   });
    // };
    // $rootScope.getElectionsDate();
    $rootScope.gotoAnchor = function (x) {
      var newHash = x;
      if ($location.hash() !== newHash) {
        // set the $location.hash to `newHash` and
        // $anchorScroll will automatically scroll to it
        $location.hash(x);
      } else {
        // call $anchorScroll() explicitly,
        // since $location.hash hasn't changed
        $anchorScroll();
      }
    };

    $rootScope.$on("IdleTimeout", function () {
      //alert("Ideal timeout called");
      // the user has timed out (meaning idleDuration + timeout has passed without any activity)
      //this is where you'd log them
      $rootScope.Logout();
    });

    $rootScope.statusGetCategories = 0;
    var getCategoriesCanceler = $q.defer();
    $rootScope.getCategories = function () {
      $rootScope.statusGetCategories = 1;
      getCategoriesCanceler.resolve();
      getCategoriesCanceler = $q.defer();
      var request = apiCall.apiCall("GET", "/categories/list", {
        root: 1,
        questions_counter: 1,
        catTypes: "Q,B",
      });
      request.timeout = getCategoriesCanceler;
      $http(request).then(
        function successCallback(response) {
          if (response.data.success) {
            $rootScope.categories = response.data.data;
            $rootScope.statusGetCategories = 2;
            $rootScope.$broadcast("EventParentCategoriesFetched");
            //MyService.ConsoleLog("Got rootScope categories");
          } else {
            $rootScope.statusGetCategories = 3;
            //MyService.ConsoleLog("Something went wrong: ", response.data);
          }
        },
        function errorCallBack(response) {
          $rootScope.statusGetCategories = 3;
          //MyService.ConsoleLog("Error: ", response);
        }
      );
    };

    $rootScope.getVerificationUrl = function () {
      var hostUrl = window.location.host;
      var port = window.location.port;
      if (port == undefined || port == "") {
        hostUrl += ":" + port;
      }

      hostUrl += "/#!/verify";

      if (hostUrl.indexOf("http") == -1) hostUrl = "http://" + hostUrl;

      return hostUrl;
    };

    $rootScope.getPasswordResetUrl = function () {
      var hostUrl = window.location.host;
      var port = window.location.port;
      if (port == undefined || port == "") {
        hostUrl += ":" + port;
      }

      hostUrl += "/#!/forgot-password/<RESET_TOKEN>";

      if (hostUrl.indexOf("http") == -1) hostUrl = "http://" + hostUrl;

      return hostUrl;
    };
    $rootScope.getEmailUnsubscribeUrl = function () {
      var hostUrl = window.location.host;
      var port = window.location.port;
      if (port == undefined || port == "") {
        hostUrl += ":" + port;
      }

      hostUrl += "/#!/email-unsubscribe";
      if (hostUrl.indexOf("http") == -1) hostUrl = "http://" + hostUrl;

      return hostUrl;
    };

    $rootScope.NewUserData = {
      zipcode: "",
      usertype: "",
    };

    if (
      tokenService.getToken() != undefined &&
      tokenService.getToken() != null &&
      tokenService.getToken() != ""
    ) {
      // $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
      //console.log("got the place one");
      $rootScope.Auth = true;
      $rootScope.User = tokenService.getUserName();
      $rootScope.UserId = tokenService.getUserId();
      $rootScope.getCategories();
      $rootScope.userRole = window.localStorage[tokenUserRole];
    }
    //MyService.ConsoleLog("App Run");
    $rootScope.$on("$routeChangeStart", function (event) {
      $timeout(function () {
        $(".growl-item .close").trigger("click");
      });
      // $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
      $rootScope.User = tokenService.getUserName();
      //}
      // //console.log("got the place two", $location.path());
      if ($location.path() != "/welcome") {
        $rootScope.getCategories();
      }
    });

    $rootScope.searchQuestion = function () {
      if (!$filter("isBlankString")($rootScope.searchQuestionKeyword)) {
        $location.path("/question-search");
      }
    };

    $rootScope.dismissLoginConfirmation = function () {
      $("#loginConfirmModal").modal("hide");
      window.history.back();
    };

    $rootScope.showLoginConfirmation = function () {
      // MyService.ConsoleLog("Show login confirmation");
      $("#loginConfirmModal").modal("show");
    };

    $rootScope.logoutConfirmed = function (hideModal) {
      $cookies.remove("isExist");
      $cookies.remove("qName");
      $cookies.remove("orgId");
      $cookies.remove("poliId");
      $cookies.remove("showLogin");
      window.localStorage.removeItem("inviterInfo");
      $cookies.remove("quizTakerIdsArr");
      window.localStorage.removeItem("isFindFollowers");
      window.localStorage.removeItem("inviterId");
      window.localStorage.removeItem("isShowSpecialMatch");
      window.localStorage.removeItem("specialQueAns");
      window.localStorage.removeItem("isShowOldResult");
      window.localStorage.removeItem("compareResult");
      window.localStorage.removeItem("isPostCard");
      // window.localStorage.removeItem("queIdsN");

      if (hideModal) $("#loginConfirmModal").modal("hide");
      var request = apiCall.apiCall("POST", "/user/logout", {
        userid: $rootScope.UserId,
      });
      $http(request).then(
        function successCallback(response) {
          //MyService.ConsoleLog("Logout successful");
        },
        function errorCallBack(response) {
          //MyService.ConsoleLog("Error: ", response);
        }
      );

      // $http.defaults.headers.common['x-access-token'] = '';
      // $rootScope.Auth = false;

      tokenService.destroyToken();
      $location.path("/signin");
      if (!hideModal) $window.location.reload();
    };

    $rootScope.Logout = function (menu) {
      if (menu) {
        $rootScope.logoutConfirmed(false);
      } else {
        $rootScope.showLoginConfirmation();
      }
    };

    $rootScope.OpenLoginPopup = function () {
      /*$rootScope.modalInstance = $uibModal.open({
        templateUrl: 'signin/user-info-popup.html',
        //scope: $rootScope,
        size: 'md',
        resolve: {
            Context: function () {
                return {

                };
            }
        }
    });*/
      if (typeof FB != "undefined" && FB != null) {
        $rootScope.statusAuthenticate = 1;
        FB.login(
          function (response) {
            //MyService.ConsoleLog(response);
            statusChangeCallback(response, "login");
          },
          {
            scope: "public_profile,email",
          }
        );
      } else {
        growl.error(
          "Facebook resources not loaded yet. Please try after some time."
        );
      }
    };

    $rootScope.LoginWithFacebook = function () {
      /*if ($.fn.validateForceFully($("#userinfoform")) == true) {}*/
      if ($("#userinfoform").validationEngine("validate") == true) {
        //MyService.ConsoleLog("all good: ", $rootScope.NewUserData);
        FB.login(
          function (response) {
            //MyService.ConsoleLog(response);
            statusChangeCallback(response, "signup");
          },
          {
            scope: "public_profile,email",
          }
        );
      }
    };

    // This is called with the results from from FB.getLoginStatus().
    function statusChangeCallback(response, optn) {
      // MyService.ConsoleLog("response",response);
      if (response.status === "connected") {
        getUserInfo(optn);
      } else if (response.status === "not_authorized") {
        growl.info("Please log " + "into this app.");
        $rootScope.statusAuthenticate = 3;
      } else {
        // alert("heh");
        growl.error("Please log " + "into Facebook.");
        $rootScope.statusAuthenticate = 3;
      }
    }

    $rootScope.suggest_organization = function (term, org_type) {
      var q = term.toLowerCase().trim();
      //MyService.ConsoleLog("term: ", term);

      var deferred = $q.defer();

      var postdata = {
        max_records: 5,
        keyword: term,
        type: org_type.toLowerCase(),
      };

      var request = apiCall.apiCall("GET", "/office/list", postdata);

      $http(request).then(
        function successCallback(response) {
          //MyService.ConsoleLog("succss: ", response);
          if (response.data.success) {
            angular.forEach(response.data.data, function (val, key) {
              response.data.data[key].value = val.title;
              response.data.data[key].label = val.title;
            });
            //MyService.ConsoleLog("got org dtaa: ", response.data.data);
            deferred.resolve(response.data.data);
            // growl.success("Tagged successfully");
          } else deferred.resolve([]);
          /*else{
        if(typeof response.data.error == "string")
            growl.error(response.data.error);
        else
            growl.error("Something went wrong");
      }*/
        },
        function errorCallback(response) {
          // growl.error("Something went wrong");
          deferred.resolve([]);
        }
      );

      return deferred.promise;
    };

    $rootScope.suggestPoliticianOffice = function (term, org_type) {
      var q = term.toLowerCase().trim();
      //MyService.ConsoleLog("term: ", term);

      var deferred = $q.defer();

      var postdata = {
        max_records: 5,
        keyword: term,
        type: org_type.toLowerCase(),
      };

      var request = apiCall.apiCall("GET", "/politician-office/list", postdata);

      $http(request).then(
        function successCallback(response) {
          //MyService.ConsoleLog("succss: ", response);
          if (response.data.success) {
            angular.forEach(response.data.data, function (val, key) {
              response.data.data[key].value = val.title;
              response.data.data[key].label = val.title;
            });
            //MyService.ConsoleLog("got org dtaa: ", response.data.data);
            deferred.resolve(response.data.data);
            // growl.success("Tagged successfully");
          } else deferred.resolve([]);
          /*else{
        if(typeof response.data.error == "string")
            growl.error(response.data.error);
        else
            growl.error("Something went wrong");
      }*/
        },
        function errorCallback(response) {
          // growl.error("Something went wrong");
          deferred.resolve([]);
        }
      );

      return deferred.promise;
    };
    $rootScope.suggestJurisdiction = function (term) {
      var q = term.toLowerCase().trim();
      //MyService.ConsoleLog("term: ", term);

      var deferred = $q.defer();

      var postdata = {
        max_records: 5,
        keyword: term,
      };

      var request = apiCall.apiCall("GET", "/jurisdiction/list", postdata);

      $http(request).then(
        function successCallback(response) {
          //MyService.ConsoleLog("succss: ", response);
          if (response.data.success) {
            angular.forEach(response.data.data, function (val, key) {
              response.data.data[key].value = val.title;
              response.data.data[key].label = val.title;
            });
            //MyService.ConsoleLog("got org dtaa: ", response.data.data);
            deferred.resolve(response.data.data);
            // growl.success("Tagged successfully");
          } else deferred.resolve([]);
          /*else{
        if(typeof response.data.error == "string")
            growl.error(response.data.error);
        else
            growl.error("Something went wrong");
      }*/
        },
        function errorCallback(response) {
          // growl.error("Something went wrong");
          deferred.resolve([]);
        }
      );

      return deferred.promise;
    };

    $rootScope.userTypeChanged = function () {
      $rootScope.NewUserData.organization = null;
      $rootScope.NewUserData.organization_name = "";
    };

    $rootScope.org_autocomplete_options = {
      suggest: function (term) {
        return $rootScope.suggest_organization(
          term,
          $rootScope.NewUserData.usertype
        );
      },
      on_select: function (selected) {
        //MyService.ConsoleLog("selected: ", selected);
        // $scope.User.organization_name = selected.label;
        $rootScope.NewUserData.organization = selected;
      },
      on_detach: function (current_value) {
        //MyService.ConsoleLog("on_detach: ", current_value);
        $rootScope.NewUserData.organization_name = current_value;
        /*try{
          if($rootScope.NewUserData.organization.label != current_value)
            $rootScope.NewUserData.organization = null;
      }
      catch(e){
          //MyService.ConsoleLog("Exceptoin: ",e)
          $rootScope.NewUserData.organization = null;
      }*/
      },
    };

    // This function is called when someone finishes with the Login
    // Button. See the onlogin handler attached to it in the sample
    // code below.
    function checkLoginState() {
      FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    }

    // Here we run a very simple test of the Graph API after login is
    // successful.  See statusChangeCallback() for when this call is made.
    function getUserInfo(optn) {
      FB.api(
        "/me",
        {
          fields: "first_name,last_name,email",
        },
        function (response) {
          //MyService.ConsoleLog("response: ", response);
          if (response != undefined && response != null && response != "") {
            $rootScope.CreateFBUser(response, optn);
          } else {
            $rootScope.statusAuthenticate = 3;
          }
        }
      );
    }
    $rootScope.Errors = [];

    $rootScope.CreateFBUser = function (fbResponse, optn) {
      MyService.ConsoleLog("CreateFBUser : ", optn);
      //MyService.ConsoleLog("CreateFBUser: ", fbResponse);
      //MyService.ConsoleLog("organization_name: ", $rootScope.NewUserData.organization_name);
      $rootScope.Errors = [];

      $rootScope.FBSignIn(fbResponse, function () {
        var postobj = {
          name: fbResponse.first_name + " " + fbResponse.last_name,
          // last_name: fbResponse.last_name,
          username: fbResponse.email.split("@")[0],
          password: "",
          email: fbResponse.email,
          zipcode: $rootScope.NewUserData.zipcode,
          type: $rootScope.NewUserData.usertype.toLowerCase(),
          isfacebooksigin: true,
          facebookid: fbResponse.id,
          verificationurl: $rootScope.getVerificationUrl(),
          unsubscribeurl: $rootScope.getEmailUnsubscribeUrl(),
        };

        switch ($rootScope.NewUserData.usertype.toLowerCase()) {
          case "advocate":
          case "press":
            try {
              if (
                $rootScope.NewUserData &&
                $rootScope.NewUserData.organization._id
              )
                postobj.office_id = $rootScope.NewUserData.organization._id;
              else {
                if (
                  $filter("isBlankString")(
                    $rootScope.NewUserData.organization_name
                  )
                ) {
                  //MyService.ConsoleLog("o: ", $rootScope.NewUserData.organization_name);
                  growl.error("Organization name is required");
                  return;
                }

                postobj.office_phone_number =
                  $rootScope.NewUserData.organization.phone_no;
                postobj.office_web_url =
                  $rootScope.NewUserData.organization.web_url;
                postobj.office_name = $rootScope.NewUserData.organization_name;
              }
            } catch (e) {
              //MyService.ConsoleLog("Exceptoin : ", e);
            }

            postobj.extention_no = $rootScope.NewUserData.extention_no;
            postobj.verification_note =
              $rootScope.NewUserData.verification_note;
            break;
          case "politician":
            postobj.name_on_ballot = $rootScope.NewUserData.name_on_ballot;
            postobj.phone_no = $rootScope.NewUserData.phone_no;
            postobj.mailing_address = $rootScope.NewUserData.mailing_address;
            try {
              if (
                $rootScope.NewUserData &&
                $rootScope.NewUserData.organization._id
              )
                postobj.office_id = $rootScope.NewUserData.organization._id;
              else {
                if (
                  $filter("isBlankString")(
                    $rootScope.NewUserData.organization_name
                  )
                ) {
                  growl.error("Office name is required");
                  return;
                }
                postobj.office_phone_number =
                  $rootScope.NewUserData.organization.phone_no;
                postobj.office_web_url =
                  $rootScope.NewUserData.organization.web_url;
                postobj.office_name = $rootScope.NewUserData.organization_name;
                postobj.office_fabebook_url =
                  $rootScope.NewUserData.organization.facebook_url;
              }
            } catch (e) {}
            break;
        }

        //MyService.ConsoleLog("FBSignIn: ", postobj);

        $http.post(baseApiUrl + "/user/signup", postobj).then(
          function (response) {
            //MyService.ConsoleLog("response: ", response);
            if (response.data.success == true) {
              $rootScope.statusAuthenticate = 2;
              //MyService.ConsoleLog($rootScope.NewUserData.usertype);
              var message = "";
              switch ($rootScope.NewUserData.usertype.toLowerCase()) {
                case "politician":
                  message =
                    "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Politician.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                  break;
                case "voter":
                  message =
                    "Until you confirm your email, you can read and explore the site, but you won't be able to write responses.";
                  break;
                case "press":
                  message =
                    "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Press.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                  break;
                case "advocate":
                  message =
                    "It may take us some time to confirm your identity.  In the meantime, you can use the site, but everything you do will have the byline 'Unknown Organization.'  As soon as we confirm who you are, all your old posts will automatically switch to your real name.";
                  break;
              }
              growl.success(message);
              $cookies.put("showLongPopUp", message);

              $rootScope.FBSignIn(fbResponse);

              // $rootScope.modalInstance.dismiss('cancel');

              $location.path("debate");
            } else if (
              response.data.error &&
              typeof response.data.error == "string"
            ) {
              if (optn == "login") {
                growl.error(
                  "User may not exist on server, please sign up first."
                );
              } else {
                growl.error("Error: " + response.data.error);
              }
              $rootScope.statusAuthenticate = 3;
            } else {
              growl.error("Something went wrong.");
              $rootScope.statusAuthenticate = 3;
            }
          },
          function (response) {
            $rootScope.statusAuthenticate = 3;
            var validataionMsg = "";
            //MyService.ConsoleLog("response: ", response)
            var errors = response.data.error.errors;
            for (var k in errors) {
              if (errors.hasOwnProperty(k)) {
                //MyService.ConsoleLog(k)
                var obj = errors[k];
                if (obj.message !== undefined)
                  validataionMsg += obj.message.replace("Path", "") + "<br/>";
              }
            }
            growl.error(validataionMsg);
          }
        );
      });
    };

    /**
     * @description Add answers after login
     */
    function saveFiveMinAnswers() {
      console.log("$cookies.get", $cookies.get("sessionId"));
      if ($cookies.get("sessionId")) {
        MyService.ConsoleLog("saveFiveMinAnswers : ", $rootScope.guestMatch);

        // alert("Inside");

        // var guestArr = $rootScope.guestMatch.filter(function (itms) {
        //   itms._id = itms.questionId;
        //   return itms;
        // });
        // MyService.ConsoleLog('<<<<<<< guestArr >>>>>>>>', guestArr);
        // $rootScope.guestMatch = '';
        // $rootScope.guestMatch = guestArr;
        var len = $rootScope.guestMatch.length;
        if (len > 0) {
          for (var i = 0; i < len; i++) {
            MyService.ConsoleLog($rootScope.guestMatch[i]);
            MyService.postAnswer($rootScope.guestMatch[i]).then(
              function (response) {
                MyService.ConsoleLog(response);
                if (response.data.success) {
                  MyService.ConsoleLog("Answer updated successfully");
                } else {
                  MyService.ConsoleLog("Error: " + response.data.error);
                }
              },
              function (err) {
                MyService.ConsoleLog("Error: ");
              }
            );

            MyService.deleteGuest($rootScope.guestMatch[i]).then(
              function (success) {
                MyService.ConsoleLog("SUccess >>>", success);
              },
              function (err) {
                MyService.ConsoleLog("Error >>", err);
              }
            );
          }
          // $rootScope.guestMatch = "";
          $cookies.remove("sessionId");
          $location.path("/findMatch/true");
        }
      }
      // else {
      //   MyService.ConsoleLog('saveFiveMinAnswers : ');
      //   MyService.ConsoleLog($rootScope.fiveMatch);
      //   var len = $rootScope.fiveMatch.length;
      //   if (len > 0) {
      //     for (var i = 0; i < len; i++) {
      //       MyService.ConsoleLog($rootScope.fiveMatch[i]);
      //       MyService.postAnswer($rootScope.fiveMatch[i]).then(
      //         function (response) {
      //           MyService.ConsoleLog(response);
      //           if (response.data.success) {
      //             MyService.ConsoleLog('Answer updated successfully');
      //           } else {
      //             MyService.ConsoleLog('Error: ' + response.data.error);
      //           }
      //         },
      //         function (err) {
      //           MyService.ConsoleLog('Error: ');
      //         }
      //       );
      //     }
      //   }
      // }
    }
    /**
     * @description Function to get records of the running candidates for researchers quiz.
     */

    $rootScope.getRunningCandidateRecords = function () {
      try {
        MyService.getRunningCandidateRecords().then(
          function (APIResponse) {
            MyService.ConsoleLog("this is me >>>>>>>", APIResponse);
            if (APIResponse && APIResponse.length > 0) {
              window.localStorage.setItem(
                "frontRunners",
                JSON.stringify(APIResponse)
              );
            }
          },
          function (error) {
            MyService.ConsoleLog("Error", error);
          }
        );
      } catch (err) {
        console.log("check err", err);
      }
    };

    $rootScope.getCurrentUserAns = function (userId) {
      MyService.getFiveMinAnswers({
        authorId: userId,
      }).then(
        function (payload) {
          MyService.ConsoleLog("app.js ans >> ", payload);
          if (payload.data.success) {
            window.localStorage.setItem(
              "fiveMatch",
              JSON.stringify(payload.data.data)
            );
            // $scope.getFiveMinQues();
          }
        },
        function (errpayload) {
          MyService.ConsoleLog(
            "getAllAnsweredQuestions errpayload >> ",
            errpayload
          );
        }
      );
    };

    $rootScope.initAuthorizedUser = function (userdata, type) {
      // body...
      window.localStorage.removeItem("frontRunners");
      window.localStorage.removeItem("isPostCard");

      MyService.ConsoleLog("userdata>>", userdata);
      $rootScope.getCurrentUserAns(userdata.id);
      MyService.ConsoleLog("type : ", type);
      tokenService.saveToken(userdata.token);
      tokenService.saveUserName(userdata.name);
      tokenService.saveUserId(userdata.id);
      tokenService.saveUserRole(userdata);
      window.localStorage.setItem("isPostCard", userdata.isPostCard);
      var udata = {
        id: userdata.id,
        lastLogin: userdata.lastLogin,
        uname: userdata.username,
        email: userdata.email,
        followlist: userdata.followlist,
        isemailverified: userdata.isemailverified,
        subject_of_deep_interest: userdata.subject_of_deep_interest,
        subject_of_interest: userdata.subject_of_interest,
        location_of_interest: userdata.location_of_interest,
        address: userdata.address,
        selectedColumns: userdata.selectedColumns,
        facebookid: userdata.facebookid,
        volunteers: userdata.volunteers,
        name: userdata.name,
      };
      //MyService.ConsoleLog("udata : ", udata);
      $rootScope.tempUserAddress = userdata.address;
      tokenService.saveUserData(JSON.stringify(udata));

      $http.defaults.headers.common["x-access-token"] = tokenService.getToken();
      // $http.defaults.headers.common['Authorization'] = tokenService.getToken();
      $rootScope.Auth = true;
      $rootScope.User = tokenService.getUserName();
      $rootScope.UserId = tokenService.getUserId();

      // if (userdata && userdata.userType == 'admin') {
      $rootScope.getRunningCandidateRecords();
      // }

      //$location.path('howToVideo');
      //$scope.historyUrl = window.localStorage.getItem("HistoryPage");
      MyService.ConsoleLog(
        "Here : ",
        window.localStorage.getItem("HistoryPage")
      );
      if (
        window.localStorage.getItem("HistoryPage") &&
        window.localStorage.getItem("HistoryPage") != ""
      ) {
        // MyService.ConsoleLog("location url <><><><><><",window.localStorage.getItem("HistoryPage"));
        $location.path(window.localStorage.getItem("HistoryPage"));
      } else {
        var isExist = $cookies.get("isExist");
        var qName = $cookies.get("qName");
        var orgId = $cookies.get("orgId");
        var poliId = $cookies.get("poliId");
        console.log("poliId", poliId);
        if (type == "signup") {
          MyService.ConsoleLog("show getting strated page");

          if ($cookies.get("showLogin") == "true") {
            console.log("qname", qName);
            console.log("orgId", orgId);
            if (qName && qName != "" && orgId && orgId != "") {
              // $location.path('/organizationQuiz/' + qName + "/" + orgId);
              // $cookies.put('showBanner', true);
              $location.path("/showPoliQuiz/" + qName + "/" + orgId);
            } else {
              $location.path("/showPoliticianAns/" + qName + "/" + poliId);
            }
          } else if (isExist == "true") {
            // alert('inside');
            // $location.path('/followersQuiz/' + qName + "/" + orgId);
            $location.path("/showFollowersQuiz/" + qName + "/" + orgId);
          } else {
            console.log("userType", userdata.userType);
            switch (userdata.userType) {
              case "voter":
                $location.path("gettingstarted");
                break;
              case "politician":
                $location.path("/gettingStartedPol");
                break;
              case "advocate":
                $location.path("/gettingStartedOrg");
                break;
              default:
                break;
            }
          }
        } else {
          // alert('here')
          MyService.ConsoleLog("cook", $cookies.get("showLogin"));

          if ($cookies.get("showLogin") == "true") {
            // console.log("qname", qName);
            // console.log("orgId", orgId);
            if (qName && qName != "" && orgId && orgId != "") {
              // $location.path('/organizationQuiz/' + qName + "/" + orgId);
              $location.path("/showPoliQuiz/" + qName + "/" + orgId);
            } else {
              $location.path("/showPoliticianAns/" + qName + "/" + poliId);
            }
          } else if (isExist == "true") {
            // alert('inside');

            // $location.path('/followersQuiz/' + qName + "/" + orgId);
            $cookies.remove("isExist");
            $location.path("/showFollowersQuiz/" + qName + "/" + orgId);
          } else {
            MyService.ConsoleLog("go to snapshot");
            $location.path("snapshot");
          }
        }
      }
      //console.log("got the place three");
      saveFiveMinAnswers();

      $rootScope.getCategories();
    };

    $rootScope.statusAuthenticate = 0;
    $rootScope.AuthenticateUser = function (postObj, type) {
      $rootScope.statusAuthenticate = 1;
      $http.post(baseApiUrl + "/user/authenticate", postObj).then(
        function (response) {
          if (response.data.success == true) {
            //MyService.ConsoleLog("Login Data");
            //MyService.ConsoleLog(response.data);
            $rootScope.initAuthorizedUser(response.data, type);
            /*
          tokenService.saveToken(response.data.token);
          tokenService.saveUserName(response.data.name);
          tokenService.saveUserId(response.data.id);
          tokenService.saveUserRole(response.data);
          var userData = {
              id: response.data.id,
              lastLogin: response.data.lastLogin,
              uname: response.data.username,
              name: response.data.name
          };
          //MyService.ConsoleLog("username: ", userData);
          tokenService.saveUserData(JSON.stringify(userData));
          $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
          $rootScope.Auth = true;
          $rootScope.User = tokenService.getUserName();
          $rootScope.UserId = tokenService.getUserId();
          $location.path('debateHome');

          $rootScope.getCategories();
          */
            //console.log("got the place Four");

            $rootScope.statusAuthenticate = 2;
          } else {
            $rootScope.statusAuthenticate = 3;
            growl.error("Invalid Username or Password. Please try again.");
          }
        },
        function (response) {
          $rootScope.statusAuthenticate = 3;
          var validataionMsg = "";
          //MyService.ConsoleLog("response: ", response);
          if (response.data && response.data.error) {
            var errors = response.data.error.errors;
            for (var k in errors) {
              if (errors.hasOwnProperty(k)) {
                //MyService.ConsoleLog(k)
                var obj = errors[k];
                if (obj.message !== undefined)
                  validataionMsg += obj.message.replace("Path", "") + "<br/>";
              }
            }
            growl.error(validataionMsg);
          }
        }
      );
    };

    $rootScope.FBSignIn = function (fbresponse, callback) {
      //MyService.ConsoleLog(fbresponse);
      $http
        .post(baseApiUrl + "/user/authenticateEmail", {
          email: fbresponse.email,
        })
        .then(
          function (response) {
            if (response.data.success == true) {
              // $rootScope.modalInstance.dismiss('cancel');
              /*
                  tokenService.saveToken(response.data.token);
                  tokenService.saveUserName(response.data.name);
                  tokenService.saveUserId(response.data.id);
                  tokenService.saveUserRole(response.data);
                  $http.defaults.headers.common['Authorization'] = tokenService.getToken();
                  $http.defaults.headers.common['x-access-token'] = tokenService.getToken();
                  $rootScope.Auth = true;
                  $rootScope.User = tokenService.getUserName();
                  $rootScope.UserId = tokenService.getUserId();

                  $location.path('debateHome');
                  */
              //MyService.ConsoleLog("Response Data :");
              //MyService.ConsoleLog(response.data);
              //MyService.ConsoleLog(response.data.facebookid);
              if (!response.data.facebookid) {
                $http
                  .post(baseApiUrl + "/user/updateFacebookId", {
                    facebookid: fbresponse.id,
                    userId: response.data.id,
                  })
                  .then(function (userresponse) {
                    $rootScope.initAuthorizedUser(response.data, "signup");
                  });
              } else {
                $rootScope.initAuthorizedUser(response.data, "signup");
              }
            } else {
              //growl.error('Invalid Username or Password. Please try again.');
              if (callback !== undefined) callback();
            }
          },
          function (response) {
            var validataionMsg = "";
            var errors = response.data.error.errors;
            for (var k in errors) {
              if (errors.hasOwnProperty(k)) {
                //MyService.ConsoleLog(k)
                var obj = errors[k];
                if (obj.message !== undefined)
                  validataionMsg += obj.message.replace("Path", "") + "<br/>";
              }
            }
            growl.error(validataionMsg);
          }
        );
    };
    $rootScope.cancel = function () {
      $rootScope.modalInstance.dismiss("close");
    };

    $rootScope.calculateSreenSizeValues = function () {
      $rootScope.screenHeight = $(document).height();
      $rootScope.screenWidth = $(document).width();
      //MyService.ConsoleLog("width: ",$rootScope.screenWidth," height: ",$rootScope.screenHeight);
    };

    $rootScope.printPage = function () {
      window.print();
    };

    $rootScope.triggerMouseLeave = function (clsName) {
      //MyService.ConsoleLog(" triggerMouseLeave ", clsName);
      // $(clsName).trigger('mouseover');
      /*$timeout(function(argument) {
        //MyService.ConsoleLog("triggered click event");
        $(".body-tag").trigger('click');
        $(".flexbox").trigger('click');
        $(".juliedropdown").trigger('click');
        $(".juliedropdown").mouseover();
    }, 1000);*/
      // $(".juliedropdown").toggleClass("open");
    };
    //MyService.ConsoleLog("path<><><>",$location.path());
    $(document).click(function (e) {
      var container = $(".juliedropdown");

      if (
        !container.is(e.target) && // if the target of the click isn't the container...
        container.has(e.target).length === 0
      ) {
        // ... nor a descendant of the container{
        $(".juliedropdown").removeClass("open");
      }
    });

    $(".juliedropdown").click(function () {
      //MyService.ConsoleLog("clicked");
      $(".juliedropdown").toggleClass("open");
    });

    angular.element($window).bind("resize", function () {
      //MyService.ConsoleLog("resize");
      $timeout(function () {
        $rootScope.calculateSreenSizeValues();
      });
    });
  },
]);

mainApp.factory("apiService", function (baseApiUrl) {
  return {
    constants: function () {
      return {
        regex: {
          username: /^[a-z][\w\.]{1,25}$/,
          password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // Contain 8 charectors, 1 uppercase
          email:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          city: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
        },
      };
    },
    apiCall: function (verb, url, data, headers) {
      ////console.log(verb, url, data, headers);
      // var root = 'http://104.131.115.102:8080';

      var root = baseApiUrl;
      ////console.log("root : ", root);

      var obj = {
        method: verb,
        url: root + url,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          //'x-access-token': window.localStorage[tokenName]
        },
        transformRequest: function (obj) {
          var str = [];
          for (var p in obj)
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          return str.join("&");
        },
        // data: data
      };

      if (verb.toLowerCase() == "get") {
        ////console.log("GET method");
        obj.params = data;
      } else {
        obj.data = data;
      }

      ////console.log("OBJ :",obj);
      return obj;
    },
  };
  /*
  const factory = {};

  factory.constants = () => {
      return {
          regex: {
              username: /^[a-z][\w\.]{1,25}$/,
              password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // Contain 8 charectors, 1 uppercase
              email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              city: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
          }
      }
  }

  factory.apiCall = (verb, url, data, headers) => {
      ////console.log(verb, url, data, headers);
      const root = 'http://104.131.115.102:8080';

      var obj = {
          method: verb,
          url: root + url,
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              // 'x-access-token': window.localStorage[tokenName]
          },
          transformRequest: function(obj) {
              var str = [];
              for (var p in obj)
                  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              return str.join("&");
          },
          // data: data
      };

      if (verb.toLowerCase() == "get") {
          ////console.log("GET method");
          obj.params = data;
      } else {
          obj.data = data;
      }

      ////console.log("OBJ :",obj);
      return obj;
  }

  return factory;
  */
});

//factory.constants = () => {
//    return {
//        regex: {
//            username: /^[a-z][\w\.]{1,25}$/,
//            password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // Contain 8 charectors, 1 uppercase
//            email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
//            city: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
//        }
//    }
//}

//factory.apiCall = (verb, url, data, headers) => {
//    ////console.log(verb, url, data, headers);
//    const root = 'http://104.131.115.102:8080';
//return {
//    method: verb,
//    url: root + url,
//    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//    transformRequest: function(obj) {
//        var str = [];
//        for(var p in obj)
//            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
//        return str.join("&");
//    },
//    data: data
//}
//}

//return factory;
//});

mainApp
  .service("apiCall", function (apiService, tokenService) {
    /*
    this.apiCall = (verb, url, data, headers) => {
        return apiService.apiCall(verb, url, data, headers);
    }
    this.constants = () => {
        return apiService.constants();
    }
    this.saveToken = (token) => {
        return apiService.saveToken(token);
    }
    this.getToken = () => {
        return tokenService.getToken();
    }
    this.destroyToken = () => {
        return apiService.destroyToken();
    }
    */
    return {
      apiCall: function (verb, url, data, headers) {
        return apiService.apiCall(verb, url, data, headers);
      },
      constants: function () {
        return apiService.constants();
      },
      saveToken: function (token) {
        return apiService.saveToken(token);
      },
      getToken: function () {
        return tokenService.getToken();
      },
      destroyToken: function () {
        return apiService.destroyToken();
      },
    };
  })
  .filter("getItemById", function () {
    //order the groupped dat with by it's key
    return function (data, id) {
      var item = null;
      var len = data.length;
      for (var i = 0; i < len; i++) {
        if (data[i]._id == id) {
          item = data[i];
          break;
        }
      }
      return item;
    };
  });
